<template>
  <div class="user page">
    <Nav></Nav>
    <div class="wrap" style="padding-top: 10px; min-height: 700px">
      <a-layout>
        <a-layout-sider>
          <Menu></Menu>
        </a-layout-sider>
        <a-layout>
          <a-layout-header>
            <a-row type="flex" justify="center" align="middle" class="top">
              <a-col :span="6">
                <a-button
                  type="primary"
                  shape="round"
                  block
                  @click="sign"
                  :disabled="isSign"
                  >签到</a-button
                >
              </a-col>
            </a-row>
          </a-layout-header>
          <a-layout-content>
            <a-calendar
              :header-render="headerRender"
              @select="onSelect"
              @panelChange="onPanelChange"
              v-model="selectedValue"
            >
              <template slot="dateCellRender" slot-scope="value">
                <span v-if="getListData(value)">
                  <a-badge status="error" text="已签到" />
                </span>
              </template>
            </a-calendar>
          </a-layout-content>
        </a-layout>
      </a-layout>
    </div>
    <Foot></Foot>
  </div>
</template>
    
    <script>
import Foot from "@/components/foot";
import Nav from "@/components/Nav.vue";
import Menu from "../user/components/menu";
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");
export default {
  components: {
    Nav,
    Foot,
    Menu,
  },
  data() {
    return {
      user: {},
      selectedValue: "",
      list: [],
      isSign: false,
    };
  },
  computed: {},
  created() {
    if (this.$route.name === "Home") this.main_swiper = true;
  },
  mounted() {
    this.user = this.storage.getItem("user");
    if (!this.user) {
      this.getUserInfo();
    }
    let today = new Date();
    this.selectedValue = moment(today);
    let nowMonth = this.selectedValue.format("YYYY-MM-DD");
    this.api
      .getTodayIsSign({})
      .then((res) => {
        if (res == 1) {
          this.isSign = true;
        }
      })
      .catch((err) => {
        console.log(err);
      });
    this.api
      .getSignList({
        date: nowMonth,
      })
      .then((res) => {
        this.list = res;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  watch: {
    // 如果路由有变化，会再次执行该方法
    $route: "fetchDate",
  },
  methods: {
    fetchDate() {
      if (this.$route.name !== "Home") {
        this.main_swiper = false;
      } else {
        this.main_swiper = true;
      }
    },
    getUserInfo() {
      this.api
        .getuserDetail({})
        .then((res) => {
          this.storage.setItem("user", res);
          this.user = res;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getListData(value) {
      let flag;
      this.list.map((date) => {
        if (date.date == value.format("YYYY-MM-DD")) {
          flag = true;
        }
      });
      return flag;
    },
    onSelect(value) {
      this.selectedValue = value;
    },
    onPanelChange(value) {
      let nowMonth = value.format("YYYY-MM-DD");
      this.api
        .getSignList({
          date: nowMonth,
        })
        .then((res) => {
          this.list = res;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    headerRender({ value, onChange }) {
      const start = 0;
      const end = 12;
      const monthOptions = [];

      const current = value.clone();
      const localeData = value.localeData();
      const months = [];
      for (let i = 0; i < 12; i++) {
        current.month(i);
        months.push(localeData.monthsShort(current));
      }

      for (let index = start; index < end; index++) {
        monthOptions.push(
          <a-select-option class="month-item" key={`${index}`}>
            {months[index]}
          </a-select-option>
        );
      }
      const month = value.month();

      const year = value.year();
      const options = [];
      for (let i = year - 10; i < year + 10; i += 1) {
        options.push(
          <a-select-option key={i} value={i} class="year-item">
            {i}
          </a-select-option>
        );
      }
      return (
        <div style={{ padding: "10px" }}>
          <a-row type="flex" justify="space-between">
            <a-col span="20"></a-col>
            <a-col>
              <a-select
                size="small"
                dropdownMatchSelectWidth={false}
                class="my-year-select"
                onChange={(newYear) => {
                  const now = value.clone().year(newYear);
                  onChange(now);
                }}
                value={String(year)}
              >
                {options}
              </a-select>
            </a-col>
            <a-col>
              <a-select
                size="small"
                dropdownMatchSelectWidth={false}
                value={String(month)}
                onChange={(selectedMonth) => {
                  const newValue = value.clone();
                  newValue.month(parseInt(selectedMonth, 10));
                  onChange(newValue);
                }}
              >
                {monthOptions}
              </a-select>
            </a-col>
          </a-row>
        </div>
      );
    },
    sign() {
      let today = new Date();
      let nowDate = moment(today).format("YYYY-MM-DD");

      this.api
        .sign({
          date: nowDate,
          planId: 1,
        })
        .then(() => {
          this.isSign = true;
          this.selectedValue = moment(today);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
    
    
    <style lang="less">
@import "~ant-design-vue/dist/antd.less";

.page {
  background: #f5f5f5;
  height: auto;
}

.wrap {
  height: 100%;

  // padding-bottom: 20px;
}

.ant-layout {
  // height: 100%;
}

.ant-layout-sider,
.ant-layout-content,
.ant-layout-header,
.ant-layout-footer {
  background: #ffffff !important;
  padding: 10px;
}

.ant-layout-sider {
  margin-right: 10px;
  text-align: center;
}

.ant-layout-content {
  margin-top: 10px;
}

.ant-layout-header {
  height: auto;

  .top {
    .ant-col {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .anticon {
      font-size: 24px;
      margin-right: 10px;
    }
  }
}

.user {
  .user-name {
    font-size: 14px;
    margin-top: 10px;
  }

  .other-info {
    text-align: left;
    margin-left: 30px;

    span {
      color: @text-color-secondary;
    }
  }
}

.menu {
  text-align: left;
  padding-left: 30px;
  line-height: 28px;
  margin-top: 30px;
  border-top: 1px solid #e2e2e2;

  dl {
    padding-top: 10px;

    dt {
      font-size: 16px;
      line-height: 28px;
    }

    dd {
      color: @text-color-secondary;
    }
  }
}

div.content-wrap {
  padding: 15px;
}
</style>